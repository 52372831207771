<template>
  <div class="vg_wrapper">
    <add-header
        @submit="submit('prodForm')"
        @cancel="cancel"
    ></add-header>
    <el-form ref="prodForm" :rules="rules" :model="prodForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="产品货号" prop="prod_no">
            <el-input v-model="prodForm.prod_no" maxlength="10" show-word-limit placeholder="请填写产品货号"></el-input>
          </el-form-item>
          <el-form-item label="产品规格" prop="prod_spec">
            <el-input v-model="prodForm.prod_spec" maxlength="50" show-word-limit placeholder="请填写产品规格"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="产品名称" prop="prod_name">
            <el-input v-model="prodForm.prod_name" maxlength="30" show-word-limit placeholder="请填写产品名称"></el-input>
          </el-form-item>
          <!-- <el-form-item label="部门团队" prop="smpl_num">
            <el-input v-model="prodForm.dept_team_id"  show-word-limit placeholder="请填写部门团对"></el-input>
          </el-form-item> -->
        </el-col>
        <el-col :md="8">
          <el-form-item label="产品类型" prop="prod_type">
            <el-select v-model="prodForm.prod_type" placeholder="请选择产品类型" clearable>
              <el-option
                  v-for="item in prodTypeGroupOpt"
                  :key="item.id"
                  :label="item.param1"
                  :value="item.param1">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
     </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {prodAPI} from "@api/modules/prod";
import addHeader from "@/views/component/addHeader";
import {optnAPI} from "@api/modules/optn";
import inputUser from "@/views/component/inputUser";

export default {
  name: "smplAddMain",
  components: {
    addHeader,
    inputUser
  },
  data() {
    return {
      rules:{
        prod_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        prod_name:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
      },
      prodForm:{
        prod_no: '',
        prod_name: '',
        prod_type: '',
        prod_spec:'',
        status:0,
        key:0,
      },
      prodTypeGroupOpt:[],
    }
  },
  watch: {
  },
  created() {
    this.getSmplType()
  },
  methods:{
    // 获取玩具类型
    getSmplType(flag){
      get(optnAPI.getAllContent,{perm_id : 10003})
      .then(res=>{
        if(res.data.code === 0) {
          this.prodTypeGroupOpt = res.data.data
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      })
      .catch(()=>{})
    },
    // 保存
    saveInfo() {
      const staffForm =  this.$refs.userData.getData()
      let prodForm1 = {}
      prodForm1 = Object.assign(prodForm1,this.prodForm,staffForm)
      post(prodAPI.addProd,prodForm1)
      .then(res => {                                                    
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          const permId = this.$route.query.perm_id
          this.jump(`/prod_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
          this.resetForm('prodForm')
        }else if(res.data.code === 7){
          this.$message({
            type:'error',
            message:'产品货号重复'
          })
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('prodForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      })
      .catch(()=>{})
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
</style>