<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="打样信息" name="first" :key="'first'">
        <childTab1/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ProdAddMain from "@/views/DevelopManagement/ProdManage/TabChild/ProdAddMain";

export default {
  name: "AcctAdd",
  components: {
    childTab1: ProdAddMain,
  },
  data() {
    return {
      activeName: "first",
    }
  },
}
</script>

<style scoped>

</style>